/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import SliceZone from '@beelineloans/cx-library/src/components/prismic-slices/SliceZone';
import { replaceWithState } from '@beelineloans/cx-library/src/utils/prismic/prismicUtils';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';

const linkResolver = require('../../linkResolver');

const Page = ({ data, pageContext, location }) => {
  const { state } = location;
  const document = data.prismicPage;
  let articles = data.allPrismicArticle?.nodes;
  const allCategories = data.allPrismicBlogCategory?.nodes;
  // const { canonical, overridePath } = pageContext;
  const { canonical, overridePath, category, pageNumber, pageCount } = pageContext;
  const path = overridePath || linkResolver(data.prismicPage);

  articles = articles?.filter((p) => p.uid !== 'required');

  // //  if (category) articles = articles.filter((p) => (p.categories || []).find(cat => cat.category1.name.toLowerCase() === category.toLowerCase()));

  if (!document) return null;

  return (
    <Template headerComponent={null} cssOverride={`.container-inner :not(#header-content-left) :not(#header-content-right) h2 { font-size:35px; } ${document.data.css}`}>
      <SEO
        path={path}
        title={replaceWithState(document.data.browser_title, state)}
        description={document.data.meta_description ? document.data.meta_description : document.data.browser_title}
        canonical={canonical}
      />
      <SliceZone
        document={document}
        articles={articles}
        allCategories={allCategories}
        includeContainer
        category={category}
        pageNumber={pageNumber}
        pageCount={pageCount}
        path={path}
        pageState={state}
      />
    </Template>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  location: PropTypes.object
};

// allArticles - where clause not working with new object. used to work with string value. showing all and filtering on page (above) instead as a workaround
// where: {categories: {category1: {name: {_eq: $category}}}}
// $category: String
export const query = graphql`
  query PageQuery($uid: String!, $categoryId: ID, $itemsPerPage: Int, $skip: Int) {
    prismicPage(lang: { eq: "en-us" }, uid: { eq: $uid }) {
      _previewable
      data {
        browser_title
        css
        folder
        meta_description
        body {
          ... on PrismicPageDataBodyHero {
            slice_type
            slice_label
            primary {
              content {
                text
                html
                richText
              }
              background_colour
              h2 {
                text
                html
                richText
              }
              title {
                text
                html
                richText
              }
              type
              nav_only
              show_apply_button
              show_star_ratings
              show_blog_categories
              show_main_menu
              menu_sign_in
              background_colour
              button_url
              button_text
              heroImage: image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
              video_text
              video {
                uri
                html
                title
                width
                height
                description
                embed_url
              }
              voomly_id
            }
          }
          ... on PrismicPageDataBodySocialProof {
            slice_type
            slice_label
            primary {
              type
            }
          }
          ... on PrismicPageDataBodyForm {
            slice_type
            slice_label
            primary {
              background_colour
              id
              title {
                html
                richText
                text
              }
              supporting_title {
                html
                richText
                text
              }
              button_text
              post_url
              success_message
              fail_message
              field_color
              submit_redirect {
                url
                link_type
                uid
                target
              }
            }
            items {
              id
              input_type
              label
              required
            }
          }
          ... on PrismicPageDataBodyFaqList {
            slice_type
            slice_label
            items {
              item_title
              item_content {
                html
                richText
                text
              }
            }
            primary {
              id
              title {
                html
                richText
                text
              }
              background_colour
              show_help_button
              help_button_text
              help_button_link {
                url
                link_type
                uid
                target
              }
            }
          }
          ... on PrismicPageDataBodyCarousel {
            slice_type
            slice_label
            primary {
              id
              title {
                html
                richText
                text
              }
              content {
                html
                richText
                text
              }
              background_colour
              reviews_type
              show_button
              button_text
              show_star_ratings
            }
          }
          ... on PrismicPageDataBodyTextAndImage {
            slice_type
            slice_label
            primary {
              id
              title_h2 {
                html
                richText
                text
              }
              title_h3 {
                html
                richText
                text
              }
              panelImage: image
              max_image_width
              fix_image_to_the_side
              fix_image_to_bottom
              uploaded_image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
              content {
                html
                richText
                text
              }
              horizontal_text_alignment
              vertical_text_alignment
              image_on_left
              background_colour
              padding_top
              padding_bottom
            }
          }
          ... on PrismicPageDataBodyContentBlock {
            slice_type
            slice_label
            primary {
              id
              background_colour
              content {
                html
                richText
                text
              }
            }
          }
          ... on PrismicPageDataBodyArticleList {
            slice_type
            slice_label
            primary {
              allow_paging
              how_many
            }
          }
          ... on PrismicPageDataBodyImage {
            slice_type
            slice_label
            primary {
              background_colour
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicPageDataBodyStandout {
            slice_type
            slice_label
            primary {
              background_colour
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              title {
                html
                richText
                text
              }
              text {
                html
                richText
                text
              }
              margin_bottom
              margin_left
              margin_right
              margin_top
              button_copy
              buttonURL: button_url {
                uid
                url
                id
                type
                link_type
                target
              }
            }
          }
          ... on PrismicPageDataBodyVideo {
            slice_label
            slice_type
            primary {
              id
              title {
                html
                richText
                text
              }
              background_colour
              video {
                uri
                html
                title
                width
                height
                description
                embed_url
              }
              voomly_id
            }
          }
          ... on PrismicPageDataBodyQuote {
            slice_type
            slice_label
            primary {
              background_colour
              text {
                html
                richText
                text
              }
            }
          }
          ... on PrismicPageDataBodySpacer {
            slice_type
            slice_label
          }
          ... on PrismicPageDataBodyHtml {
            slice_type
            slice_label
            primary {
              background_colour
              code
            }
          }
          ... on PrismicPageDataBodyCta {
            slice_type
            slice_label
            primary {
              horizontal_alignment
              vertical_padding
              button_text
              button_link {
                link_type
                url
                uid
                target
              }
            }
          }
          ... on PrismicPageDataBodyCodeComponent {
            id
            primary {
              component_data
              component_name
              id
            }
            slice_type
            slice_label
          }
          ... on PrismicPageDataBodyNumberedList {
            id
            slice_type
            slice_label
            items {
              item_title
              item_content {
                html
                richText
                text
              }
            }
            primary {
              bottom_image
              bottom_image_width
              id
              background_colour
              top_image
              top_image_width
              top_image_uploaded {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              bottom_image_uploaded {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              title {
                html
                richText
                text
              }
              image_title {
                html
                richText
                text
              }
            }
          }
          ... on PrismicPageDataBodyPlantAndCoffee {
            id
            slice_type
            slice_label
            items {
              button_url {
                link_type
                url
                uid
                target
              }
              button_text
            }
            primary {
              id
              heading: title
              text {
                html
                richText
                text
              }
              hide_plant
              hide_coffee
            }
          }
          ... on PrismicPageDataBodyPanels {
            slice_type
            slice_label
            primary {
              padding_top
              padding_bottom
              panel {
                link_type
                uid
                url
                type
                target
                slug
                lang
                id
                document {
                  ... on PrismicPanelTextAndImage {
                    id
                    data {
                      notes
                      title_h2 {
                        html
                        richText
                        text
                      }
                      title_h3 {
                        html
                        richText
                        text
                      }
                      panelImage: image
                      max_image_width
                      uploaded_image {
                        url
                        alt
                      }
                      content {
                        html
                        richText
                        text
                      }
                      horizontal_text_alignment
                      vertical_text_alignment
                      image_on_left
                      background_colour
                    }
                  }
                  ... on PrismicFooterCtaPanel {
                    id
                    data {
                      apply_button_text
                      notes
                      schedule_button_text
                      show_apply_button
                      show_schedule_button
                      title {
                        html
                        richText
                        text
                      }
                      supporting_text {
                        html
                        richText
                        text
                      }
                      apply_button_link {
                        url
                        uid
                        type
                        target
                        slug
                        link_type
                      }
                      schedule_button_link {
                        link_type
                        url
                        uid
                        type
                        target
                        slug
                      }
                    }
                    first_publication_date
                  }
                }
              }
            }
          }
        }
      }
      type
      uid
      href
      id
      first_publication_date(formatString: "")
      last_publication_date(formatString: "")
      tags
      url
      lang
    }
    allPrismicArticle(
      sort: { fields: [data___publish_date], order: DESC }
      filter: { lang: { eq: "en-us" }, uid: { ne: "required" }, data: { categories: { elemMatch: { category1: { id: { eq: $categoryId } } } } } }
      limit: $itemsPerPage
      skip: $skip
    ) {
      nodes {
        _previewable
        type
        uid
        lang
        last_publication_date(formatString: "")
        first_publication_date(formatString: "")
        data {
          title {
            html
            richText
            text
          }
          intro
          publish_date(formatString: "")
          read_time
          show_about_the_author
          show_footer_cta
          show_related_articles
          meta_description
          external_url {
            url
          }
          author {
            uid
            type
            slug
            id
            document {
              ... on PrismicAuthor {
                id
                data {
                  about
                  firstname
                  lastname
                  profile_image {
                    url
                    uid
                    link_type
                    slug
                  }
                }
              }
            }
          }
          categories {
            category1 {
              uid
              type
              slug
              id
              link_type
              document {
                ... on PrismicBlogCategory {
                  id
                  data {
                    button_color
                    name
                  }
                }
              }
            }
          }
          image {
            url
            dimensions {
              height
              width
            }
            alt
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        totalCount
        perPage
      }
    }
    allPrismicBlogCategory {
      nodes {
        _previewable
        data {
          button_color
          name
        }
      }
    }
  }
`;

export default withPrismicPreview(Page);
