import * as React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { setTrackingCookie } from '@beelineloans/cx-library/src/utils/helpers';
import linkResolver from '@beelineloans/cx-library/src/utils/prismic/linkResolver';
import { StateProvider } from '@beelineloans/cx-library/src/state/stateProvider';
import { pushCrispChat } from '@beelineloans/cx-library/src/utils/dataLayer';
import Page from './src/templates/page';
import Article from './src/templates/article';
import LoanGuide from './src/templates/loanGuide';

const onRouteUpdate = () => {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    const data = window.dataLayer || [];
    const path = document.location.pathname;
    const event = `route-change-${path}`;

    data.push({
      event,
      route: document.location.pathname
    });
  }, 50);
};

const onClientEntry = () => {
  setTrackingCookie();
  const context = {
    kind: 'user',
    anonymous: true,
    key: 'anonymous-default'
  };

  if (window) {
    const client = LDClient.initialize(process.env.GATSBY_LD_CLIENT_ID, context);
    client
      .waitForInitialization()
      .then(() => {
        // initialization succeeded, flag values are now available
        const enableBob = client.variation('website-enable-bob-chat', false);
        pushCrispChat(enableBob);
      })
      .catch(() => {
        // initialization failed
        pushCrispChat(false);
      });

    window.addEventListener('gtmCustomEvent', (e) => {
      if (e.detail && e.detail.event) {
        if (e.detail.event === 'redTrackClickID') {
          setTrackingCookie(`?rtkcid=${e.detail.data}&rtkcmpid=${e.detail.campaign}`);
        }
      }
    });
  } else {
    pushCrispChat(false);
  }
};

const wrapRootElement = ({ element }) =>
  process.env.GATSBY_PROD === 'true' ? (
    <StateProvider>{element}</StateProvider>
  ) : (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            article: Article,
            loan_guide: LoanGuide
          })
        }
      ]}
    >
      <StateProvider>{element}</StateProvider>
    </PrismicPreviewProvider>
  );

export { onClientEntry, onRouteUpdate, wrapRootElement };
